import React from 'react';

import Link from 'utils/localized-link';

import ProductsFeatured from 'components/sections/products-featured';
import ProductsHeader from 'components/sections/products-header';
import Banner from 'components/sections/banner';
import Layout from 'components/layout';
import SEO from 'components/seo';

const bannerImage = 'home/robot-banner-bottom2.jpg';

const bannerButtons = [
  {
    linkComponent: Link,
    linkProps: {
      to: '/contacts?subject=Products'
    },
    light: true,
    noArrow: true,
    small: true,
    text: 'works.banner.buttons.contacts'
  }
];

const ProductsPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="products.title" />
    <ProductsHeader />
    <ProductsFeatured />
    <Banner
      buttons={bannerButtons}
      image={bannerImage}
      title="home.banner.title"
    />
  </Layout>
);

export default ProductsPage;
