import React from 'react';

import Featured from 'components/featured';

import './styles.scss';

const featuredImage1 = 'products/app-client.png';
const featuredImage2 = 'products/app-courier.png';
const featuredImage3 = 'products/app-transporter.jpg';
const featuredImage4 = 'products/marketplace.jpg';
const featuredRobot  = 'products/robot-sidewalk.jpg';
const featuredMission  = 'products/mission-control3.png';

const ProductsFeatured = () => (
  <div className="products-featured">
    <div className="container-md products-featured__container">
      
      <a name="robot" id="robot" className="products-featured__featured-anchor">&nbsp;</a>
      <Featured
        classNames="products-featured__featured-row"
        description="products.featured.f1.description"
        image={featuredRobot}
        title="products.featured.f1.title"
      />

      <a name="mission_control" id="mission_control" className="products-featured__featured-anchor">&nbsp;</a>
      <Featured
        classNames="products-featured__featured-row"
        description="products.featured.f2.description"
        image={featuredMission}
        title="products.featured.f2.title"
        invert
      />

      <a name="customer" id="customer" className="products-featured__featured-anchor">&nbsp;</a>
      <Featured
        classNames="products-featured__featured-row"
        description="products.featured.f3.description"
        image={featuredImage1}
        title="products.featured.f3.title"
      />
      
      <a name="courier" id="courier" className="products-featured__featured-anchor">&nbsp;</a>
      <Featured
        classNames="products-featured__featured-row"
        description="products.featured.f4.description"
        image={featuredImage2}
        title="products.featured.f4.title"
        invert
      />
      <a name="drive" id="drive" className="products-featured__featured-anchor">&nbsp;</a>
      <Featured
        classNames="products-featured__featured-row"
        description="products.featured.f5.description"
        image={featuredImage3}
        title="products.featured.f5.title"
      />
      <a name="store" id="store" className="products-featured__featured-anchor">&nbsp;</a>
      <Featured
        classNames="products-featured__featured-row"
        description="products.featured.f6.description"
        image={featuredImage4}
        title="products.featured.f6.title"
        invert
      />
     
    </div>
  </div>
);

export default ProductsFeatured;
